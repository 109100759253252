<template>
  <div class="info">
    <div class="picture-1">
      <img
        src="https://oss.pigwan.net/wapi/upload/images/2022/01/info-1.jpg"
        alt=""
      />
    </div>
    <div class="info-1">
      <h1>提供高端能源和电力技术装备</h1>
      <p>
        许继绿能
        作为国内装备制造业的领先企业，积极履行大型国有企业的社会责任，致力于为国民经济和社会发展提供高端能源和电力技术装备。
      </p>
      <div class="info-icon-list">
        <div class="info-icon-item">
          <div class="info-icon-item-icons">
            <div class="info-item">
              <img
                src="https://oss.pigwan.net/wapi/upload/images/2022/01/1.svg"
                alt="新能源汽车-许继绿能官网"
                title="新能源汽车-许继绿能官网"
              />
              <h5>新能源汽车</h5>
            </div>
          </div>
          <h3>家用</h3>
        </div>
        <div class="info-icon-item" style="flex: 3; margin-left: 40px">
          <div class="info-icon-item-icons">
            <div class="info-item">
              <img
                src="https://oss.pigwan.net/wapi/upload/images/2022/01/2.svg"
                alt="风力发电-许继绿能官网"
                title="风力发电-许继绿能官网"
              />
              <h5>风力发电</h5>
            </div>
            <div class="info-item">
              <img
                src="https://oss.pigwan.net/wapi/upload/images/2022/01/3.svg"
                alt="太阳能发电-许继绿能官网"
                title="太阳能发电-许继绿能官网"
              />
              <h5>太阳能发电</h5>
            </div>
            <div class="info-item">
              <img
                src="https://oss.pigwan.net/wapi/upload/images/2022/01/4.svg"
                alt="火力发电-许继绿能官网"
                title="火力发电-许继绿能官网"
              />
              <h5>火力发电</h5>
            </div>
            <div class="info-item">
              <img
                src="https://oss.pigwan.net/wapi/upload/images/2022/01/5.svg"
                alt="核电站-许继绿能官网"
                title="核电站-许继绿能官网"
              />
              <h5>核电站</h5>
            </div>
            <div class="info-item">
              <img
                src="https://oss.pigwan.net/wapi/upload/images/2022/01/6.svg"
                alt="电网-许继绿能官网"
                title="电网-许继绿能官网"
              />
              <h5>电网</h5>
            </div>
          </div>
          <h3>工业</h3>
        </div>
      </div>
    </div>

    <div class="service">
      <img
        src="https://oss.pigwan.net/wapi/upload/images/2022/01/info-2.jpg"
        alt="">
      <div class="service-content">
        <h2>服务全球客户</h2>
        <p>为清洁能源生产、传输、配送以及高效使用提供全面的技术和服务支撑</p>
        <div class="service-list">
          <div class="service-list-item"><h3>15+</h3><h5>应用领域</h5></div>
          <div class="service-list-item"><h3>40+</h3><h5>项目案例</h5></div>
        </div>
        <p class="service-description">该数据截止至2022年3月8日</p>
      </div>
    </div>
    <div class="service2">


      <div class="service2-item" v-for="item in articles" :key="item.id">
        <div class="service2-cover">
          <img :src="item.cover" alt="">
        </div>
        <div class="service2-content">
          <h5>用科技助力未来生活</h5>
          <h2>{{ item.title }}</h2>
          <h3>智慧电力系统，保障民生安全</h3>
          <p>{{ item.summary }}</p>
          <button> 查看详情 </button>
          <p class="desc">*图片仅供参考</p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import article from "../../api/article";
import state from "../../store/state";

export default {
  name: "Info",
  data(){
    return {
      articles:[]
    }
  },
  mounted() {
    document.title = "走进绿能 - "+state.websiteSet.website.title

    this.getArticle()
  },
  methods:{
    getArticle(){
      const that = this
      let website_id = state.websiteSet.website.id;
      article.getArticles(website_id,14,1,3).then((res)=>{
        if (res && res.code === 0){
          that.articles = res.data.data
        }else{
          alert('无数据');
        }
      }).catch((error)=>{
        console.log(error)
      })
    }
  }
};
</script>

<style scoped>
.picture-1 {
  width: 100%;
}
.picture-1 img {
  width: 100%;
}
.info-1 {
  text-align: center;
  width: 850px;
  margin: 0 auto;
  padding: 50px 0 0;
  color: #000f16;
}
.info-1 h1 {
  font-weight: lighter;
  padding: 0;
  font-size: 40px;
  line-height: 60px;
  color: #000f16;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(90deg, #004b64, #00bebe);
  text-align: center;
}
.info-1 p {
  padding: 0;
  font-size: 20px;
  font-weight: 300;
  line-height: 32px;
  margin-top: 20px;
}
.info-icon-list {
  display: flex;
  padding: 40px 0 60px;
  text-align: center;
}
.info-icon-item {
  flex: 1;
  box-sizing: inherit;
}
.info-icon-item-icons {
  display: flex;
}
.info-icon-item h3 {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #00bebe;
  border-top: 1px solid #000;
  padding-top: 20px;
  margin-top: 20px;
  font-weight: 300;
}
.info-item {
  margin-top: 30px;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.info-item img {
  margin: auto;
  width: 40px;
  height: 49px;
}
.info-item h5 {
  font-size: 12px;
  color: #54585a;
  line-height: 20px;
  margin-top: 10px;
  font-weight: 400;
}
.service {
  position: relative;
  background-color: #000;
  padding-top: 0;
  display: block;
  height: auto;
  align-items: stretch;
  flex-direction: column;
  justify-content: space-between;
  width: 100vw;
}
.service img {
  width: 100%;
}
.service-content{
  top: 50%;
  transform: translateY(-50%);
  padding: 0 0 0 120px;
  z-index: 1;
  color: #fff;
  position: absolute;
  left: 0;
  box-sizing: inherit;
}
.service-content h2{
  font-size: 32px;
  line-height: 56px;
  font-weight: 300;
  color: #FFFFFF;
}
.service-content p{
  font-size: 14px;
  line-height: 1.6em;
}
.service-list{
  display: flex;
  margin-top: 40px;
}
.service-list-item{
  flex: 1;
}
.service-list-item h3{
  font-size: 48px;
  color: #00bebe;
  line-height: 64px;
}
.service-list-item h5{
  font-size: 14px;
  font-weight: 300;
  color: #FFFFFF;
}
.service-description{
  position: static;
  margin: 10vh 0 0;
  bottom: -320px;
  z-index: 1;
  left: 5vw;
  font-size: 10px;
  color: #939799;
}

.service2{
  padding-top: 60px;
  position: relative;
}
.service2-item{
  display: flex;
  padding: 0;
  margin: 0 auto 70px;
  width: 1000px;
  background-color: transparent;
  color: #000;
  font-weight: 300;
}
.service2-cover{
  width: 600px;
  height: 360px;
}
.service2-cover img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.service2-content{
  width: 360px;
  margin-left: 40px;
}
.service2-content h5{
  margin-top: 0;
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
}
.service2-content h2{
  font-size: 32px;
  font-weight: 300;
  line-height: 44px;
  margin-top: 32px;
}
.service2-content h3 {
  font-size: 14px;
  color: #00bebe;
  margin: 10px 0 14px;
  line-height: 1.6em;
}
.service2-content p{
  font-size: 14px;
  color: #000f16;
  line-height: 24px;
  margin: 10px 0 40px;
}
.service2-content button{
  font-weight: lighter;
  font-size: 12px;
  height: 30px;
  line-height: 30px;
  width: 120px;
  color: #000;
  border: 1px solid #000000;
  background: none;
  margin: 0;
  padding: 0;
  text-align: center;
  letter-spacing: 2px;
  display: block;
  cursor: pointer;
}
.service2-content .desc{
  font-size: 10px;
  color: #939799;
  margin-top: 30px;
}
</style>
